.module-a {
    height: 100%
}
.module-a-map-context {
  display: flex;
  /* height: 500px; */
  height: 100%;
}
.sidebar-content {
  padding: 12px;
}
.sidebar-separator {
  margin: 24px 0;
}
.sidebar-content .sidebar-instructions {
  margin-bottom: 12px;
}
.esri-sketch.esri-widget {
  width: fit-content;
}
.error-color {
  color: #d32f2f;
}