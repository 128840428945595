#root > div {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#root > div > .MuiBox-root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.module-a-selected .tabpanel {
    overflow: hidden;
}

.module-a-not-selected .tabpanel {
    overflow: auto;
}

.content-container {
    box-sizing: border-box;
    padding: 0 5px;
    width: 100%;
    max-width: 900px;
    margin: 10px auto;
}
