div.logo-section {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin: 40px 20px 20px;
  justify-content: space-around;
}

div.logo-section img {
  height: 100px;
}