.copyright-info {
  text-align: center;
  font-size: 13px;
  /* color: #1f417d; */
  padding: 16px 0;
}

.subtle-link {
  color: unset !important;
  color: inherit !important;
  &:hover {
    color: unset !important;
    color: inherit !important;
  }
}