input.shapefile-upload-input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 0;
  height: 0;
}
span.shapefile-filename {
  margin-left: 20px;
}

#shapefileUploadForm {
  margin-top: 12px;
}