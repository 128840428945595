html,body,#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #0449a4;
  text-decoration: none;
  font-weight: bold;
  &:visited {
    color: #0449a4;
  }
  &:hover {
    text-decoration: underline;
    color: #007bff;
  }
  @media print {
    font-weight: normal;
  }
}
